import React from 'react'

import GlobalPrices from '../components/views/globalPrices/globalPrices'
import { LayoutLoggedIn } from '../components/views/layout'

export default function GlobalPricesPage() {
   return (
      <LayoutLoggedIn>
         <GlobalPrices />
      </LayoutLoggedIn>
   )
}
