import React from 'react'

const GlobalPrices = () => {
   return (
      <div>
         <h1>Global Prices</h1>
      </div>
   )
}

export default GlobalPrices
